import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="./logo192.png" alt="SDMH"/>
        <div>
          <p>Software Development</p>
          <p>Michał Horodelski</p> 
        </div>      
      </header> 
    </div>
  );
}

export default App;
